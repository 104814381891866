<template>
  <div>
    <template>
      <!-- <div>
        <v-breadcrumbs
          :items="breadcrumb"
          class="px-0 pt-0"
        >
        </v-breadcrumbs>
      </div> -->
    </template>
    <router-link
      to="/forum"
      class="text-decoration-none text-subtitle-2 font-weight-bold"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </router-link>
    <h3 class="my-8">
      Buat Forum
    </h3>
    <v-card class="pa-4">
      <v-card-text>
        <div class="mb-4">
          <h3>
            Informasi Forum
          </h3>
          <span>
            Informasi Untuk Forum
          </span>
        </div>
        <div>
          <v-autocomplete
            v-model="topic"
            :items="categories"
            label="Topik"
            return-object
            item-text="title"
            item-value="uuid"
            dense
            outlined
            clearable
            open-on-clear
          >
          </v-autocomplete>
          <!-- <small class="error--text" v-show="isNullRegencies">provinsi yang anda pilih belum memiliki kabupaten</small> -->
        </div>
        <div>
          <v-text-field
            v-model="post.title"
            label="Judul Post"
            outlined
            dense
            :rules="[validation.required]"
          >
          </v-text-field>
        </div>
        <div class="mb-4">
          <FileInput
            :value="post.image"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          >
          </FileInput>
        </div>
        <div class="mb-4">
          <CKEditor
            :value="post.name"
            label="Content"
            outlined
            dense
            @input="handleCKeditor($event)"
          ></CKEditor>
        </div>
        <div>
          <h3 class="mb-4">
            Status Forum
          </h3>
          <div class="d-flex">
            <div class="me-4">
              <v-switch
                v-model="post.is_private"
              >
              </v-switch>
            </div>
            <div>
              <h5>Private</h5>
              <span>(Hanya anda yang bisa melihat forum)</span>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          large
          :loading="isLoadingButton"
          :disabled="!formValid || !post.image || !topic"
          @click="addPost()"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="cancelAction"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiArrowLeft, mdiImageMultipleOutline } from '@mdi/js'
import CKEditor from '../../components/CkEditor.vue'
import FileInput from '../../components/FileInput.vue'

export default {
  name: 'ForumAdd',
  components: {
    FileInput,
    CKEditor,
  },
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
        mdiArrowLeft,
      },
      post: {
        title: '',
        content: '',
        image: null,
        is_private: '',
      },
      isLoadingButton: false,
      user_uuid: null,
      topic: null,
      categories: [],
      school: {},
      school_uuid: null,
      validation: {
        required,
      },
      tempContent: '',
      tempImage: [],
      detail: '',
      formValid: false,
    }
  },
  watch: {
    post: {
      handler() {
        const valid = []
        const requiredField = ['title', 'content', 'name']
        Object.entries(this.post).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getCategories()
  },
  methods: {
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''

      return this.$router.back()
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      this.post.content = event

      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    async addPost() {
      this.isLoadingButton = true
      if (this.topic === null) {
        this.showSnackbar({
          text: 'choose topic first !!',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      this.formData = new FormData()
      this.formData.append('topic_uuid', this.topic.uuid)
      this.formData.append('user_id', this.user_uuid)
      this.formData.append('content', this.post.content)
      this.formData.append('title', this.post.title)
      this.formData.append('image', this.post.image)
      this.formData.append('is_private', this.post.is_private ? 1 : 0)
      if (this.post.image === null) {
        this.showSnackbar({
          text: 'please add thumbnail first !!',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      const tempImage = this.tempContent.split('"')
      tempImage.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          this.tempImage.push(img)
        }
      })
      const image = this.post.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.add('forum', this.formData).then(
        ({ data }) => {
          this.forums = data.data
          this.showSnackbar({
            text: 'Add post successfully',
            color: 'success',
          })
          this.isLoadingButton = false

          return this.$router.back()
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
    },
    async getCategories() {
      await this.$services.ApiServices.list('forumtopic', { school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    getFile(data) {
      this.post.image = data
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
